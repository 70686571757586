import _extends from "@babel/runtime/helpers/esm/extends";
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';

export var buildFormattedPriceLabels = function buildFormattedPriceLabels(_ref) {
  var fullPrice = _ref.fullPrice,
    price = _ref.price,
    quantity = _ref.quantity,
    paymentMethodDescription = _ref.paymentMethodDescription;
  var formattedPrice = currencyFormatter({
    value: price
  });
  var formattedInstallment = currencyFormatter({
    value: (fullPrice || price) / quantity
  });
  return (price || fullPrice) && quantity ? {
    formattedInstallment: "".concat(quantity, "x de ").concat(formattedInstallment, " ").concat(paymentMethodDescription || ''),
    formattedPrice: formattedPrice
  } : {};
};

export var buildTitleByDescription = function buildTitleByDescription(_ref2) {
  var _description$replace;
  var description = _ref2.description,
    removeString = _ref2.removeString;
  return description ? description == null ? void 0 : (_description$replace = description.replace(removeString, '')) == null ? void 0 : _description$replace.trim() : description;
};

export var getFinancialServicesContent = function getFinancialServicesContent(_ref3) {
  var id = _ref3.id,
    serviceContent = _ref3.serviceContent;
  var financialServicesContent = serviceContent.financialServicesContent;
  return financialServicesContent == null ? void 0 : financialServicesContent.find(function (_ref4) {
    var code = _ref4.code;
    return code === id;
  });
};

export var parseDescriptionValue = function parseDescriptionValue(value) {
  var startSentence = (value == null ? void 0 : value.length) > 1 ? 'dos seguros' : 'do seguro';
  var descriptions = value.join(', ').replace(/, ([^,]*)$/, ' e $1').toLowerCase();
  return "".concat(startSentence, " ").concat(descriptions);
};

export var parseFinancialServices = function parseFinancialServices(_ref5) {
  var product = _ref5.product,
    serviceContent = _ref5.serviceContent;
  var _product$financialSer = product.financialServices,
    financialServices = _product$financialSer === void 0 ? [] : _product$financialSer;
  return financialServices == null ? void 0 : financialServices.map(function (_ref6) {
    var id = _ref6.id,
      offers = _ref6.offers;
    var financialServiceContent = getFinancialServicesContent({
      id: id,
      serviceContent: serviceContent
    });
    return _extends({}, financialServiceContent, {
      offers: offers
    });
  });
};