import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import styled from 'styled-components';
import { themeGet, getPaletteColor, getMediaQuery } from '@magalu/stereo-ui-styles';
import Text from '@magalu/stereo-ui/atoms/Text';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Button from '@magalu/stereo-ui/atoms/Button';
export var ContinueButton = styled(Button).attrs(function (props) {
  return _extends({
    color: 'secondary',
    mt: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var GridContainer = styled(Grid)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n  gap: ", "px;\n\n  ", " {\n    grid-template-columns: 2fr 1fr;\n    grid-template-rows: 1fr;\n  }\n"])), themeGet('space.4'), themeGet('space.4'), getMediaQuery('medium'));
export var ItemWithPriceContainer = styled(Grid).attrs(function (props) {
  return _extends({
    gridTemplateColumns: '2fr 1fr'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: baseline;\n"])));
export var ModalContent = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  line-height: ", ";\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
});
export var PriceText = styled(Text).attrs(function (props) {
  return _extends({
    color: 'text.scratched'
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var Subtotal = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: ", "px;\n"])), function (props) {
  return themeGet('fontWeights.bold')(props);
}, function (props) {
  return themeGet('fontSizes.5')(props);
});
export var SubtotalValue = styled(Text)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n  margin-left: 4px;\n"])), function (props) {
  return getPaletteColor('primary.base')(props);
}, function (props) {
  return themeGet('fontSizes.5')(props);
}, function (props) {
  return themeGet('fontWeights.bold')(props);
});
export var TermLink = styled(Text)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  text-decoration: underline;\n  margin-left: 4px;\n"])));