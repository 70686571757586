import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { shape, string, arrayOf, func, number } from 'prop-types';
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import { Icon } from '@magalu/stereo-ui-icons';
import Text from '@magalu/stereo-ui/atoms/Text';
import Box from '@magalu/stereo-ui/atoms/Box';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import { parsePlaceholders } from '@magalu/mixer-structure';
import { textEllipsis } from '@magalu/mixer-utils';
import { useFinancialService } from '../FinancialServicesProvider';
import { buildFormattedPriceLabels, getFinancialServicesContent, parseDescriptionValue } from '../utils';
import { ContinueButton, GridContainer, ItemWithPriceContainer, ModalContent, PriceText, Subtotal, SubtotalValue, TermLink } from './Summary.styles';
var Summary = function Summary(_ref) {
  var serviceContent = _ref.serviceContent,
    product = _ref.product,
    handleClickContinue = _ref.handleClickContinue;
  var authorizationTermText = serviceContent.authorizationTermText;
  var installment = product.installment,
    productPrice = product.price,
    title = product.title;
  var _ref2 = installment || {},
    paymentMethodDescription = _ref2.paymentMethodDescription,
    installmentsQty = _ref2.quantity;
  var _useFinancialService = useFinancialService(),
    selectedServices = _useFinancialService.selectedServices;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isAuthTermModalOpen = _useState2[0],
    setIsAuthTermModalOpen = _useState2[1];
  var hasSelectedServices = Object.values(selectedServices).length > 0;
  var totalServicesDescription = Object.values(selectedServices).reduce(function (acc, selected) {
    acc.push(selected.description);
    return acc;
  }, []);
  var totalServicesPrice = Object.values(selectedServices).reduce(function (acc, selected) {
    return acc + selected.price;
  }, 0);
  var totalAuthorizationTerm = Object.keys(selectedServices).reduce(function (acc, selected) {
    var selectedContent = getFinancialServicesContent({
      id: selected,
      serviceContent: serviceContent
    });
    var _selectedServices$sel = selectedServices[selected].wageCompensation;
    _selectedServices$sel = _selectedServices$sel === void 0 ? {} : _selectedServices$sel;
    var wageCompensationAmount = _selectedServices$sel.amount,
      wageCompensationPercentage = _selectedServices$sel.percentage;
    var parsedChargingTerms = parsePlaceholders(selectedContent == null ? void 0 : selectedContent.chargingTerms, {
      wageCompensationAmount: currencyFormatter({
        value: wageCompensationAmount
      }),
      wageCompensationPercentage: "".concat(wageCompensationPercentage, "%")
    });
    return "".concat(acc, "<p>&nbsp;</p>").concat(parsedChargingTerms);
  }, authorizationTermText);
  var subtotalBestPrice = Number(productPrice == null ? void 0 : productPrice.bestPrice) + totalServicesPrice;
  var subtotalFullPrice = Number(productPrice == null ? void 0 : productPrice.fullPrice) + totalServicesPrice;
  var _buildFormattedPriceL = buildFormattedPriceLabels({
      fullPrice: subtotalFullPrice,
      paymentMethodDescription: paymentMethodDescription,
      price: subtotalBestPrice,
      quantity: installmentsQty
    }),
    formattedInstallment = _buildFormattedPriceL.formattedInstallment,
    formattedSubtotal = _buildFormattedPriceL.formattedPrice;
  var formattedPrice = {
    installments: formattedInstallment,
    productPrice: currencyFormatter({
      value: productPrice == null ? void 0 : productPrice.bestPrice
    }),
    subtotal: formattedSubtotal,
    totalServicesPrice: currencyFormatter({
      value: totalServicesPrice
    })
  };
  var parsedTerms = parsePlaceholders(totalAuthorizationTerm, {
    description: parseDescriptionValue(totalServicesDescription),
    totalPrice: formattedPrice.totalServicesPrice
  });
  return React.createElement(Box, null, React.createElement(Text, null, "Resumo"), React.createElement(GridContainer, null, React.createElement(Grid, {
    gap: 3
  }, React.createElement(ItemWithPriceContainer, null, React.createElement(Flex, null, React.createElement(Text, {
    "data-testid": "summary-product-title",
    fontWeight: "bold"
  }, textEllipsis(title, 30))), React.createElement(PriceText, {
    "data-testid": "summary-product-price"
  }, formattedPrice.productPrice)), Object.values(selectedServices).map(function (service) {
    return React.createElement(ItemWithPriceContainer, {
      "data-testid": "summary-service-item",
      key: "summary-service-".concat(service.description)
    }, React.createElement(Flex, {
      alignItems: "center",
      gap: 2
    }, React.createElement(Icon, {
      color: "text.scratched",
      name: "Done"
    }), React.createElement(Text, {
      color: "text.scratched"
    }, service.description)), React.createElement(PriceText, null, currencyFormatter({
      value: service.price
    })));
  }), hasSelectedServices && React.createElement(Text, {
    fontSize: "small"
  }, "Ao clicar em continuar voc\xEA aceita o", React.createElement(TermLink, {
    "data-testid": "summary-auth-link",
    as: "span",
    onClick: function onClick() {
      return setIsAuthTermModalOpen(true);
    }
  }, "Termo de autoriza\xE7\xE3o de cobran\xE7a"))), React.createElement(Grid, {
    gap: 2,
    justifySelf: "end"
  }, React.createElement(Box, {
    lineHeight: "display"
  }, React.createElement(Subtotal, null, "Subtotal:"), React.createElement(SubtotalValue, {
    "data-testid": "summary-subtotal",
    as: "span"
  }, "".concat(formattedPrice.subtotal, " no PIX"))), (formattedPrice == null ? void 0 : formattedPrice.installments) && React.createElement(Text, {
    "data-testid": "summary-installments",
    fontWeight: "bold",
    color: "footer"
  }, "ou em at\xE9 ".concat(formattedPrice == null ? void 0 : formattedPrice.installments)), React.createElement(ContinueButton, {
    "data-testid": "summary-continue-btn",
    onClick: function onClick() {
      return handleClickContinue(selectedServices);
    }
  }, hasSelectedServices ? 'Contratar e continuar' : 'Ir para sacola'))), React.createElement(Dialog, {
    "data-testid": "summary-auth-term-modal",
    onClickOutside: function onClickOutside() {
      return setIsAuthTermModalOpen(false);
    },
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: isAuthTermModalOpen,
    showClose: true,
    titleStyle: {
      alignItems: 'center',
      columnGap: 4,
      forwardedAs: Flex,
      width: 'calc(100% - 32px)'
    }
  }, React.createElement(ModalContent, {
    dangerouslySetInnerHTML: {
      __html: parsedTerms
    },
    "data-testid": "auth-term-content"
  })));
};
process.env.NODE_ENV !== "production" ? Summary.propTypes = {
  handleClickContinue: func.isRequired,
  product: shape({
    price: shape({
      bestPrice: string,
      installment: shape({
        paymentMethodDescription: string,
        quantity: number
      }),
      title: string
    })
  }),
  serviceContent: shape({
    authorizationTermText: string,
    financialServicesContent: arrayOf(shape({
      chargingTerms: string
    }))
  })
} : void 0;
Summary.defaultProps = {
  product: {},
  serviceContent: {
    authorizationTermText: '',
    financialServicesContent: []
  }
};
export default Summary;