import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Box from '@magalu/stereo-ui/atoms/Box';
import { Icon } from '@magalu/stereo-ui-icons';
import { routePush, textEllipsis } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container } from './FloatText.styles';
var FloatText = function FloatText(_ref) {
  var staticProps = _ref["static"];
  var text = staticProps.text,
    path = staticProps.path,
    spa = staticProps.spa,
    truncateLength = staticProps.truncateLength,
    icon = staticProps.icon;
  var iconName = icon.name,
    _icon$width = icon.width,
    width = _icon$width === void 0 ? 30 : _icon$width,
    _icon$height = icon.height,
    height = _icon$height === void 0 ? 30 : _icon$height;
  var handleClick = function handleClick() {
    if (path) routePush({
      path: path,
      spa: spa
    });
  };
  return React.createElement(Container, null, React.createElement(Flex, {
    alignItems: "center",
    gap: "20px"
  }, React.createElement(Icon, {
    name: iconName,
    color: "text.light",
    width: width,
    height: height,
    onClick: handleClick
  }), React.createElement(Box, null, React.createElement(Text, {
    fontSize: 1,
    as: "p"
  }, truncateLength ? textEllipsis(text, truncateLength) : text))));
};
process.env.NODE_ENV !== "production" ? FloatText.propTypes = {
  "static": shape({
    icon: shape({
      height: number,
      name: string,
      width: number
    }),
    path: string,
    spa: bool,
    text: string.isRequired,
    truncateLength: number
  })
} : void 0;
FloatText.defaultProps = {
  "static": {
    icon: {
      name: 'ArrowBack'
    },
    path: undefined,
    spa: true,
    truncateLength: 50
  }
};
FloatText.ssr = true;
export default withLayoutProps(FloatText);