import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import { getPaletteColor, themeGet } from '@magalu/stereo-ui-styles/build/utils';
export var Container = styled(Box).attrs(function (props) {
  return _extends({}, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  position: fixed;\n  z-index: ", ";\n  border-bottom: 1px solid #eee;\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),\n    0px 1px 3px rgba(0, 0, 0, 0.2);\n  background-color: ", ";\n  padding: 10px;\n  top: 0;\n"])), function (props) {
  return themeGet('zIndices.3')(props);
}, function (props) {
  return getPaletteColor('background.white')(props);
});